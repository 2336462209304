<template>
  <f7-page class="product-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProductNavigationComponent title="" type="back" searchname="searchcountrylist" @searched="search" />
    </template>

    <section class="container">
      <div class="title">
        <h1>{{ $t.getTranslation('LBL_COUNTRIES') }}</h1>
      </div>
      <div class="country-container" :style="{ 'background-image': 'url(' + require('@/assets/images/nature-bg.jpg') + ')' }" v-for="country in list" :key="'country_' + country.CountryId" @click="onViewCountry(country.CountryDigit)">
        <div class="country">
          <div class="country-texts">
            <span class="flag-icon">
              <!-- <country-flag :country="country.CountryDigit" size="big"/> -->
              <img class="flag-img" :src="country.imgurl" loading="lazy" @error="setAltImg" />
            </span>
            <h1 class="country-name">
              {{ country.CountryName }}
            </h1>
            <span class="countryshop-now">{{ $t.getTranslation('LBL_SHOP_NOW') }}</span>
          </div>
        </div>
      </div>

      <div>
        <NoDataFoundComponent v-if="list.length <= 0" :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_DATA_FOUND')" :button="true" :button-text="$t.getTranslation('LBL_CONTACT_CS')" button-link="/chat/" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, watch, defineAsyncComponent } from 'vue'
import { configs } from '@/utils/configs.js'
import { helpers } from '@/utils/helpers.js'
import { get } from '@/utils/axios'
import { useStore } from '@/store'
// import CountryFlag from 'vue-country-flag-next'
import { findFlagUrlByIso2Code, findFlagUrlByIso3Code } from 'country-flags-svg'
// import ProductNavigationComponent from '@/components/navigations/ProductNavigationComponent.vue'

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))

export default defineComponent({
  name: 'CountryListPage',
  components: {
    ProductNavigationComponent,
    NoDataFoundComponent: defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ '@/components/NoDataFoundComponent.vue'))
  },
  props: { f7router: Object },
  setup(props) {
    const store = useStore()

    const $t = inject('$translation')

    const list = ref([])
    const showPreloader = ref(false)
    const searchValue = ref('')

    let page = 1
    let size = 30
    let lastPage = 1
    let isSearch = false

    const onViewCountry = (countryCode) => {
      props.f7router.navigate({ name: 'countryViewPage', params: { code: countryCode } })
    }

    const getData = async () => {
      let ret = await get(`/country/list?page=${page}&size=${size}`, {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
        SearchField: 'MULTIPLE',
        SearchType: 'LIKE',
        SearchValue: searchValue.value || '',
        mode: 'COUNTRYCOUNT'
      })

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          //item.imgurl = `https://countryflagsapi.com/png/${item.CountryCode}`
          let flagUrl = findFlagUrlByIso2Code(item.CountryCode)
          if (item.CountryCode.length > 2) {
            flagUrl = findFlagUrlByIso3Code(item.CountryCode)
          }
          item.imgurl = flagUrl

          //check if the country has products then hide it..
          let product = await get(`/mobile/product/elastic/list?page=1&size=1&SearchValue=&RegionId=&SubRegionId=&SupplierCode=&LanguageCode=EN&CountryCode=${item.CountryCode}&WineryCode=&afterSearch=`, {})

          if (product?.totalData === 0) {
            continue
          }

          list.value.push(item)
        }

        lastPage = ret?.lastPage
        page++
      }
    }

    const loadMore = () => {
      return lastPage >= page ? getData() : false
    }

    const search = (value) => {
      searchValue.value = value
      page = 1
      lastPage = 1
      list.value = []
      if (isSearch) {
        return true
      }
      isSearch = true
      getData()
      isSearch = false
    }

    const setAltImg = (event) => {
      event.target.src = ``
    }

    onMounted(() => {
      getData()
    })

    return { onViewCountry, list, showPreloader, search, store, setAltImg }
  }
})
</script>

<style scoped>
.product-page {
  padding: 10px;
}
.container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.country-container {
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px 0px 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  overflow: hidden;
  cursor: pointer;
}
.country {
  width: 140px;
  height: 140px;
  background-image: url('../../assets/images/COUNTRY-CIRCULAR.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.country-texts {
  text-align: center;
}
.country-name {
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.countryshop-now {
  text-transform: uppercase;
  color: white;
}
.flag {
  border-radius: 5px;
}
.flag-img {
  width: 60px;
}

@media only screen and (max-width: 360px) {
  .country-container {
    width: 140px;
    height: 140px;
  }
  .country {
    width: 110px;
    height: 110px;
  }
  .country-name {
    font-size: 12px;
  }
  .countryshop-now {
    font-size: 12px;
  }
  .flag-img {
    width: 45px;
  }
  .title {
    width: calc(100% - 30px);
  }
  .container {
    justify-content: space-around;
  }
}
</style>
